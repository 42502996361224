import React from 'react'
import { Drawer } from 'antd'

import './IBottomDrawer.scss'
import HeadIcon from '../../assets/images/girl-hmm.webp'

export default function IBottomDrawer({
    open = false,
    onOpen,
    title = '',
    content = '',
    headIcon = HeadIcon,
    header = true,
    height = '65vh',
    maskClosable = true,
}) {
    const onClose = () => {
        onOpen(false)
    }

    return (
        <div className="IBottomDrawer" id="IBottomDrawer">
            <Drawer
                open={open}
                title={false}
                key={'IBottomDrawer'}
                height={height}
                closable={false}
                onClose={onClose}
                placement={'bottom'}
                getContainer={() => document.getElementById('IBottomDrawer')}
                maskClosable={maskClosable}
            >
                <div className="IBottomDrawer-content">
                    {header && (
                        <div className="IBottomDrawer-content-head">
                            <img src={headIcon} alt="icon" />
                        </div>
                    )}

                    <div className="IBottomDrawer-content-text">
                        <p>{title}</p>
                    </div>
                    <div className="IBottomDrawer-content-content">
                        {content}
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
