import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTelegram } from '../../hooks/useTelegram'
import { useUser } from '../../services'

import './Header.scss'
import useFormattedNumber from '../../hooks/useFormattedNumber'
import LogoIcon from '../../assets/images/logo-v1.jpg'

export default function Header({ reload = false }) {
    const [isScrolled, setIsScrolled] = useState(false)

    const { user } = useTelegram()
    const navigate = useNavigate()

    const { useGetUserWallet } = useUser()
    const { data, refetch } = useGetUserWallet(user?.id)

    const handleScroll = () => {
        const offset = window.scrollY
        setIsScrolled(offset > 50)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (reload) {
            refetch()
        }
    }, [reload])

    return (
        <div className={isScrolled ? 'Header active' : 'Header'}>
            <div className="Header-wrapper">
                <div
                    className="Header-wrapper-username"
                    onClick={() => navigate('/profile')}
                >
                    <img src={LogoIcon} alt="" className="avatar-i" />
                    <span>
                        {user?.username?.length > 10
                            ? user?.username?.substr(0, 10) + '...'
                            : user?.username}
                    </span>
                </div>
                <div className="Header-wrapper-profit">
                    <div className="profit">
                        бонус: {useFormattedNumber(Number(data?.bonus) || 0)}
                    </div>
                </div>
            </div>
        </div>
    )
}
