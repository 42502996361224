import { PATHS } from './constants'

import HomePage from '../pages/HomePage'
import WelcomePage from '../pages/WelcomePage'
import ProfilePage from '../pages/ProfilePage'
import TelegramChannelRedirectPage from '../pages/TelegramChannelRedirectPage'
import EarnPage from '../pages/EarnPage'
import WinnersPage from '../pages/WinnersPage'
import GamePage from '../pages/GamePage'

const ROUTES = [
    {
        path: PATHS.main,
        component: HomePage,
        active: false,
        name: 'main',
    },
    {
        path: PATHS.welcome,
        component: WelcomePage,
        active: true,
        name: 'subscribe_page',
    },
    {
        path: PATHS.profile,
        component: ProfilePage,
        active: true,
        name: 'profile',
    },
    {
        path: PATHS.tgredirect,
        component: TelegramChannelRedirectPage,
        active: true,
        name: 'tgredirect',
    },
    {
        path: PATHS.earn,
        component: EarnPage,
        active: true,
        name: 'earn',
    },
    {
        path: PATHS.winners,
        component: WinnersPage,
        active: true,
        name: 'winners',
    },
    {
        path: PATHS.game,
        component: GamePage,
        active: true,
        name: 'game',
    },
]

export const getRoutes = (user) => {
    if (user.isSubscribed === false) {
        localStorage.removeItem('is_user_subscribed')
    }
    const isSubscribed = localStorage.getItem('is_user_subscribed') || false
    if (isSubscribed) {
        return ROUTES.filter((route) => route.name !== 'subscribe_page')
    }
    return ROUTES.filter((route) => route.name === 'subscribe_page')
}

export default ROUTES
