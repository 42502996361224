export const PATHS = {
    main: '/',
    welcome: '/welcome',
    profile: '/profile',
    userCards: '/user-cards',
    bonusCards: '/bonus-cards',
    tgredirect: '/tgredirect',
    earn: '/earn',
    winners: '/winners',
    game: '/game',
}
