import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

import { getRoutes } from './routes'
import Loading from '../components/Loading'
import WelcomePage from '../pages/WelcomePage'
// import TelegramChannelRedirectPage from '../pages/TelegramChannelRedirectPage'

export const Router = ({ user, chatId }) => (
    <Suspense fallback={<Loading isLoading={true} />}>
        <Routes>
            {getRoutes(user).map((route) => {
                const Component = <route.component />

                return (
                    <Route
                        key={`ROUTE${route.path}`}
                        path={route.path}
                        element={Component}
                    />
                )
            })}
            <Route key="route_subscribe" path="*" element={<WelcomePage />} />
        </Routes>
        {/* {chatId ? (
            <Routes>
                {getRoutes(user).map((route) => {
                    const Component = <route.component />

                    return (
                        <Route
                            key={`ROUTE${route.path}`}
                            path={route.path}
                            element={Component}
                        />
                    )
                })}
                <Route
                    key="route_subscribe"
                    path="*"
                    element={<WelcomePage />}
                />
            </Routes>
        ) : (
            <Routes>
                <Route
                    key="tgredirect"
                    path="*"
                    element={<TelegramChannelRedirectPage />}
                />
            </Routes>
        )} */}
    </Suspense>
)

export default Router
