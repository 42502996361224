import { useState } from 'react'
import { Input } from 'antd'

import IBottomDrawer from '../IBottomDrawer/IBottomDrawer'
import AnimateButton from '../AnimateButton'
import HeaderIcon from '../../assets/images/3d-business-young-woman-studying-online.png'
import './EarnModalStyles.scss'

export default function EarnModal({
    open = false,
    task,
    setOpen,
    checkCode,
    loading = false,
}) {
    const [code, setCode] = useState('')

    const handleClickButton = () => {
        if (code.trim() === '') {
            console.error('Code cannot be empty')
        } else {
            checkCode({ code: code.trim(), task })
            setCode('')
        }
    }

    return (
        <div className="EarnModal">
            <IBottomDrawer
                height={'70vh'}
                open={open}
                onOpen={(e) => setOpen(e)}
                headIcon={HeaderIcon}
                title="Пишите код из историй Instagram!"
                content={
                    <div className="EarnModal-form">
                        <Input
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            size="large"
                            placeholder="Код"
                        />
                        <AnimateButton
                            fixed={false}
                            onClick={() => handleClickButton()}
                            loading={loading}
                            width={'100%'}
                            left="0"
                        >
                            Проверить
                        </AnimateButton>
                    </div>
                }
            />
        </div>
    )
}
