import React from 'react'

import Loading from '../Loading'
import './AnimateButton.scss'

export default function AnimateButton({
    onClick = () => {},
    fixed = false,
    loading = false,
    children,
    width = '90%',
    left = '',
    right = '',
}) {
    return (
        <div
            className={fixed ? 'AnimateButton fixed' : 'AnimateButton'}
            onClick={() => onClick()}
            style={{ left, right }}
        >
            <button className="btn-custon-animate" style={{ width }}>
                <i className="animation"></i>
                {loading ? (
                    <Loading
                        isLoading={true}
                        height={40}
                        color="#fff"
                        fixed={fixed}
                    />
                ) : (
                    children
                )}

                <i className="animation"></i>
            </button>
        </div>
    )
}
