import { useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'

import taskApi from './taskApi'
import { TASK_KEY } from './taskConstants'

const useTask = () =>
    useMemo(
        () => ({
            useGetTasks: (chatId) =>
                useQuery(
                    [TASK_KEY.tasks, chatId],
                    () => taskApi.getTasks(chatId),
                    {
                        staleTime: 3000,
                    }
                ),
            useCompleteTask: () =>
                useMutation(({ taskId, chatId }) =>
                    taskApi.completeTask({ taskId, chatId })
                ),
            useUpdateLoadingTask: () =>
                useMutation(({ taskId, chatId }) =>
                    taskApi.updateLoadingTask({ taskId, chatId })
                ),
            useCheckCodeTask: () =>
                useMutation(({ taskId, chatId, code }) =>
                    taskApi.checkCodeTask({ taskId, chatId, code })
                ),
            useGetWinners: (chatId) =>
                useQuery(
                    [TASK_KEY.winners, chatId],
                    () => taskApi.getWinner(chatId),
                    {
                        staleTime: 3000,
                    }
                ),
        }),
        []
    )

export default useTask
