import axios from '../../utils/axios'
import { TASK_KEY } from './taskConstants'

const API_KEY = process.env.REACT_APP_API_KEY
const LOCAL_CHAT_ID = localStorage.getItem('jovid_chat_id')

const taskApi = {
    async getTasks(chatId) {
        const { data } = await axios.get(
            `${TASK_KEY.tasks}?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },

    async completeTask({ taskId, chatId }) {
        const { data } = await axios.post(
            `${TASK_KEY.taskComplete}?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            { taskId, chatId }
        )

        return data
    },

    async updateLoadingTask({ taskId, chatId }) {
        const { data } = await axios.post(
            `${TASK_KEY.updateLoadingTask}?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            { taskId, chatId }
        )

        return data
    },

    async checkCodeTask({ taskId, chatId, code }) {
        const { data } = await axios.post(
            `${TASK_KEY.checkTaskCode}?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`,
            { taskId, chatId, code }
        )

        return data
    },

    async getWinner(chatId) {
        const { data } = await axios.get(
            `${TASK_KEY.winners}?chatId=${
                chatId || LOCAL_CHAT_ID
            }&api_key=${API_KEY}`
        )

        return data
    },
}

export default taskApi
