import React from 'react'
import { Button, message } from 'antd'
import { useUser } from '../../services/User'
import { useTelegram } from '../../hooks/useTelegram'

import './WelcomePage.scss'

export default function WelcomePage() {
    const [messageApi, contextHolder] = message.useMessage()
    const { user } = useTelegram()

    const { useCheckSubscription } = useUser()
    const userMutation = useCheckSubscription()
    const channelurl = process.env.REACT_APP_CHANNEL_NAME

    const openTelegramChannel = () => {
        window.open(`https://t.me/${channelurl}`, '_blank')
    }

    const handleCheckSubscribtion = () => {
        userMutation.mutate(user?.id || '927908860', {
            onSuccess: (res) => {
                if (res?.isSubscribed) {
                    localStorage.setItem('is_user_subscribed', '1')
                    window.location.reload()
                } else {
                    messageApi.open({
                        type: 'error',
                        content:
                            'Вы еще не подписаны, пожалуйста подпишитесь на наш канал!',
                    })
                }
            },
        })
    }

    return (
        <div className="WelcomePage">
            {contextHolder}
            <div className="container">
                <div className="WelcomePage-wrapper">
                    <div className="WelcomePage-wrapper-head">
                        <img
                            src={
                                'https://admin.chinahouse.su/assets/logo-2.24cfbded.svg'
                            }
                            alt="Welcome"
                        />
                    </div>
                    <div className="WelcomePage-wrapper-title">
                        <h4>Добро пожаловать в конкурс от ChinaHouse!</h4>
                        <p>
                            Каждую неделю топ-10 игроков получают призы! Не
                            упусти возможность увеличить свои бонусы и стать
                            победителем. Собирай друзей, играй вместе и
                            выигрывай еще больше!
                        </p>
                    </div>
                    <div className="WelcomePage-wrapper-action">
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => openTelegramChannel()}
                            block
                        >
                            Подписаться
                        </Button>
                        <Button
                            onClick={() => handleCheckSubscribtion()}
                            size="large"
                            loading={userMutation.isLoading}
                            block
                        >
                            Проверить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
