import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import './index.css'
import './styles/_theme.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider, theme } from 'antd'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#7f5ff0',
                        },
                        algorithm: theme.darkAlgorithm,
                        components: {
                            Button: {
                                /* here is your component tokens */
                            },
                        },
                    }}
                >
                    <App />
                </ConfigProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>
)

reportWebVitals()
