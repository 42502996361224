import { useState, useEffect } from 'react'

function useFormattedNumber(num) {
    const [formattedNumber, setFormattedNumber] = useState('')

    useEffect(() => {
        function formatNumber(num) {
            if (num >= 1e9) {
                return (num / 1e9).toFixed(1).replace(/\.0$/, '') + ' млрд'
            }
            if (num >= 1e6) {
                return (num / 1e6).toFixed(1).replace(/\.0$/, '') + ' млн'
            }
            if (num >= 1e3) {
                return (num / 1e3).toFixed(1).replace(/\.0$/, '') + ' тыс'
            }
            return num.toString()
        }

        setFormattedNumber(formatNumber(num))
    }, [num])

    return formattedNumber
}

export default useFormattedNumber
