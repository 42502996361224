import { ArrowUpOutlined, CopyOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic, Progress, List, message } from 'antd'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import AnimateButton from '../../components/AnimateButton'

import './ProfilePage.scss'
import { useUser } from '../../services'
import { useTelegram } from '../../hooks/useTelegram'
import Leaderboard from './components/Leaderboard/Leaderboard'

const twoColors = {
    '0%': '#7f5ff0',
    '100%': '#7f5ff0',
}
const inviteFriendsText = `
Играй со мной и зарабатывай! Наш бот поможет тебе заработать, а за приглашенных друзей ты получаешь бонусы. 
🎁  Пригласи 10 друзей и получи 100000 бонус`

export default function ProfilePage() {
    const { user } = useTelegram()

    const [messageApi, contextHolder] = message.useMessage()

    const { useGenerateKentId, useGetReferredUsers, useGetUserWallet } =
        useUser()
    const generateKentIdMutate = useGenerateKentId()

    const { data: dataUsers } = useGetReferredUsers(user?.id)
    const { data: dataUserWallet } = useGetUserWallet(user?.id)
    const users = dataUsers?.referreds || []

    const handleOpenInvite = (kentId, copy = false) => {
        const invText = `https://t.me/${process.env.REACT_APP_APP_NAME}/start?start=${kentId} ${inviteFriendsText}`
        const url = `https://t.me/share/url?url=${encodeURIComponent(invText)}`

        if (copy) {
            copyToClipboard(invText)
                .then(() => {})
                .catch((err) => {})
        } else {
            window.location.href = url
        }
    }

    const handleGenerate = (arg = false) => {
        generateKentIdMutate.mutate(user?.id, {
            onSuccess: (res) => {
                console.log('res', res)

                if (res?.kentId) {
                    handleOpenInvite(res.kentId, arg)
                } else {
                    console.error('Missing kentId in the response')
                }
            },
            onError: (error) => {
                console.error('Error generating Kent ID:', error)
            },
        })
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Текст скопирован в буфер обмена!',
                    })
                })
                .catch((err) => {
                    fallbackCopyTextToClipboard(text)
                })
        } else {
            fallbackCopyTextToClipboard(text)
        }
    }

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea')
        textArea.value = text

        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = 0
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            const successful = document.execCommand('copy')
            const msg = successful ? 'успешно' : 'не успешно'
        } catch (err) {
            console.error(
                'Ошибка при копировании текста с помощью execCommand: ',
                err
            )
        }

        document.body.removeChild(textArea)
    }

    return (
        <div className="ProfilePage">
            {contextHolder}
            <Header />
            <div className="container">
                <div className="ProfilePage-wrapper">
                    <div className="ProfilePage-wrapper-main">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card bordered={false} title="">
                                    <Progress
                                        type="circle"
                                        percent={users?.count}
                                        strokeColor={twoColors}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card
                                    bordered={false}
                                    style={{ minHeight: 168 }}
                                >
                                    <Statistic
                                        title="Бонус"
                                        value={dataUserWallet?.bonus}
                                        precision={0}
                                        valueStyle={{
                                            color: '#7f5ff0',
                                        }}
                                        prefix={<ArrowUpOutlined />}
                                        suffix=""
                                    />
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card
                                    bordered={false}
                                    style={{ marginTop: 8 }}
                                    type="inner"
                                >
                                    <Statistic
                                        title="Друзья"
                                        value={users?.count || 0}
                                        precision={0}
                                        valueStyle={{
                                            color: '#7f5ff0',
                                        }}
                                        prefix={<ArrowUpOutlined />}
                                        suffix="*"
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Leaderboard wallet={dataUserWallet} />
                        <div className="ProfilePage-wrapper-main-refferals">
                            <h4>
                                Список ваших друзей{' '}
                                <span>({users?.count || 0})</span>
                            </h4>
                            <List
                                style={{
                                    marginBottom: 16,
                                }}
                                itemLayout="horizontal"
                                dataSource={users?.rows}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <div className="Leaderboard-content-icon">
                                                    {item?.username
                                                        ?.substring(0, 2)
                                                        .toUpperCase()}
                                                </div>
                                            }
                                            title={item?.username}
                                            description={item?.firstName}
                                        />
                                        <div className="ProfilePage-wrapper-main-refferals-bonus">
                                            +10000
                                        </div>
                                    </List.Item>
                                )}
                            />
                            <div className="dflex">
                                <AnimateButton
                                    fixed={true}
                                    onClick={() => handleGenerate(false)}
                                    loading={generateKentIdMutate.isLoading}
                                    width={'60vw'}
                                    left="10px"
                                >
                                    Пригласить друга
                                </AnimateButton>
                                <AnimateButton
                                    fixed={true}
                                    fixedPosition="right"
                                    onClick={() => handleGenerate(false)}
                                    loading={generateKentIdMutate.isLoading}
                                    width={'30vw'}
                                    right="10px"
                                >
                                    <span style={{ fontSize: 22 }}>
                                        <CopyOutlined />
                                    </span>
                                </AnimateButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
