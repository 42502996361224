import { useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'

import userApi from './userApi'
import { USER_KEY } from './userConstants.js'

const useUser = () =>
    useMemo(
        () => ({
            useCheckSubscription: () =>
                useMutation((chatId) => userApi.checkSubscription(chatId)),
            useGenerateKentId: () =>
                useMutation((chatId) => userApi.generateKentId(chatId)),
            useGetReferredUsers: (chatId) =>
                useQuery([USER_KEY.getReferredUsers, chatId], () =>
                    userApi.getReferredUsers(chatId)
                ),
            useGetUser: (chatId) =>
                useQuery([USER_KEY.getUser, chatId], () =>
                    userApi.getUser(chatId)
                ),
            useConnectUserAddress: () =>
                useMutation((params) => userApi.connectUserAddress(params)),
            useGetUserWallet: (chatId) =>
                useQuery(
                    [USER_KEY.wallet, chatId],
                    () => userApi.getUserWallet(chatId),
                    {
                        staleTime: 3000,
                    }
                ),
            useGetLiderboard: (chatId) =>
                useQuery([USER_KEY.liderboard, chatId], () =>
                    userApi.getLiderboard(chatId)
                ),
            useMiningBonus: () =>
                useMutation(({ chatId, mine }) =>
                    userApi.miningBonus({ chatId, mine })
                ),
        }),
        []
    )

export default useUser
