import React, { useState } from 'react'
import { BarChartOutlined } from '@ant-design/icons'
import IBottomDrawer from '../../../../components/IBottomDrawer/IBottomDrawer'
import './Leaderboard.scss'
import HeaderIcon from '../../../../assets/images/girl-woow.png'
import { useUser } from '../../../../services'
import useFormattedNumber from '../../../../hooks/useFormattedNumber.js'

export default function Leaderboard({ wallet }) {
    const [open, setOpen] = useState(false)

    const getRandomColor = () => {
        const colors = [
            '#201f30',
            '#7f5ff0',
            'rgb(122, 6, 6)',
            'rgb(2, 80, 2)',
            '#28273a',
            '#000000',
        ]

        const randomIndex = Math.floor(Math.random() * colors.length)
        return colors[randomIndex]
    }

    const { useGetLiderboard } = useUser()
    const { data } = useGetLiderboard(wallet?.chatId)
    const liderboards = data?.users || []
    const countUsers = data?.count || 0

    return (
        <div className="Leaderboard">
            <div className="Leaderboard-content" onClick={() => setOpen(true)}>
                <div className="d-flex">
                    <div className="Leaderboard-content-icon">
                        {wallet?.username?.substring(0, 2).toUpperCase()}
                    </div>
                    <div className="Leaderboard-content-user">
                        <div className="Leaderboard-content-user-username">
                            {wallet?.username?.length > 15
                                ? wallet?.username?.substr(0, 15) + '...'
                                : wallet?.username}
                        </div>
                        <div className="Leaderboard-content-user-bonus">
                            бонус: {useFormattedNumber(wallet?.bonus || 0)}
                        </div>{' '}
                        <div className="Leaderboard-content-user-bonus">
                            место: #{wallet?.place || 0}
                        </div>
                    </div>
                </div>
                <div className="Leaderboard-content-open">
                    <p>Таблица лидеров</p>
                    <BarChartOutlined />
                </div>
            </div>
            <IBottomDrawer
                height={'75vh'}
                open={open}
                onOpen={(e) => setOpen(e)}
                headIcon={HeaderIcon}
                title="Таблица лидеров: Топ-10 пользователей получают призы каждую неделю."
                content={
                    <div className="Leaderboard-list">
                        <h4>
                            Участники <span>{countUsers}</span>
                        </h4>
                        {liderboards?.map((user, index) => {
                            index += 1

                            return (
                                <div
                                    className="Leaderboard-content"
                                    key={user.id}
                                >
                                    <div className="d-flex">
                                        <div
                                            className="Leaderboard-content-icon"
                                            style={{
                                                background: getRandomColor(),
                                            }}
                                        >
                                            {!user.username
                                                ? user?.firstName
                                                      ?.substring(0, 2)
                                                      .toUpperCase()
                                                : user?.username
                                                      ?.substring(0, 2)
                                                      .toUpperCase()}
                                        </div>
                                        <div className="Leaderboard-content-user">
                                            <div className="Leaderboard-content-user-username">
                                                {user.username
                                                    ? user.username
                                                    : user.firstName}
                                            </div>
                                            <div className="Leaderboard-content-user-bonus">
                                                бонус:{' '}
                                                {user?.DrawWallet?.bonus || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Leaderboard-content-open">
                                        #{index}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            />
        </div>
    )
}
